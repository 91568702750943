import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/sr'; // Importovanje srpske lokalizacije
import "./style.scss";
import {events, holidays} from "./prazniciDogadjaji";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.tz.setDefault("Europe/Belgrade");
dayjs.locale('sr'); // Postavljanje lokalizacije na srpski
const weekDays = ['Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota', 'Nedelja'];
const borderStyle = {borderWidth: "1px", borderStyle: "solid", borderColor: "black", textAlign: "center"};
const weekStyle = {display: "grid", gridTemplateColumns: "repeat(7,1fr)"};

function Calendar({year = 2024, startDay = 30, startMonth = 9}) {
    const start = dayjs.tz(`${year}-${startMonth}-${startDay}T00:00:00`, "Europe/Belgrade").startOf('month');
    const end = dayjs.tz(`${year + 1}-09-30T00:00:00`, "Europe/Belgrade").startOf('day');
    let days = [];
    let months = [];
    for (let date = start; date.isBefore(end) || date.isSame(end); date = date.add(1, 'day')) {
        days.push(
            {
                day: date,
                event: (events.find((event) => {
                    let after = date.isSame(dayjs(event.start)) || date.isAfter(dayjs(event.start));
                    let before = date.isSame(dayjs(event.end)) || date.isBefore(dayjs(event.end));
                    return after && before;
                })) || null,
                holiday: (holidays.find((event) => {
                    let after = date.isSame(dayjs(event.start)) || date.isAfter(dayjs(event.start));
                    let before = date.isSame(dayjs(event.end)) || date.isBefore(dayjs(event.end));
                    return after && before;
                })) || null
            }
        );
    }
    for (let date = start; date.isBefore(end) || date.isSame(end); date = date.add(1, 'month')) {
        months.push(date);
    }
    months = months.map((month) => (
            {
                name: dayjs(month).format("MMMM"),
                year: dayjs(month).format("YYYY"),
                days: days.filter(({day}) => day.month() === month.month() && day.year() === month.year())
            }
        )
    );
    console.log(months);
    return (
        <div className="container py">
            <div className="work-calendar">
                <h3>{year} godina</h3>
                {months.map((month) => {
                    return <div className="month-wraper" key={`${month.name}-${month.year}`}>
                        <h2>{month.name}</h2>
                        <DayNames/>
                        <MonthDay month={month}/>
                    </div>;
                })}
            </div>
        </div>
    );
}

function DayNames() {
    return <div className="weekDay">
        {weekDays.map((dan) => {
            return <div key={dan} className="weekDay-name">{dan}</div>;
        })}
    </div>;
}

function MonthDay({month}) {
    let monthDays = new Array(month.days[0].day.day() === 0 ? 6 : month.days[0].day.day() - 1).fill(null);

    return <div className="month">
        {[...monthDays, ...month.days].map(
            (day, idx) => day ?
                <div className="month-day exist" key={idx}>
                    <p>{day.day.format('D')}</p>
                    <p>{day.event?.name}</p>
                    <p>{day.holiday?.name}</p>
                </div> :
                <div className="month-day empty" key={`empty-${idx}`}></div>
        )}
    </div>;
}


export default Calendar;