export const events = [
    {
        name: "Početak nastave u zimskom semestru - sve godine studija",
        start: "2024-10-05",
        end: "2024-10-10"
    },
    {
        name: "Nadoknada nastave",
        start: "2025-01-27",
        end: "2025-01-31"
    }
];
export const holidays = [
    {
        name: "Uskrs",
        start: "2025-04-18",
        end: "2025-04-21"
    }
];